var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "__side_nav"
  }, [_c('router-link', {
    staticClass: "btn",
    attrs: {
      "to": '/admin/users/userSegments/GoldUsers'
    }
  }, [_vm._v(" Gold "), _vm.goldUsersCount ? _c('span', [_vm._v("(" + _vm._s(_vm.goldUsersCount) + ")")]) : _vm._e()]), _c('router-link', {
    staticClass: "btn",
    attrs: {
      "to": '/admin/users/userSegments/SilverUsers'
    }
  }, [_vm._v(" Silver "), _vm.silverUsersCount ? _c('span', [_vm._v("(" + _vm._s(_vm.silverUsersCount) + ")")]) : _vm._e()]), _c('router-link', {
    staticClass: "btn",
    attrs: {
      "to": '/admin/users/userSegments/BronzeUsers'
    }
  }, [_vm._v(" Bronze "), _vm.bronzeUsersCount ? _c('span', [_vm._v("(" + _vm._s(_vm.bronzeUsersCount) + ")")]) : _vm._e()])], 1), _c('div', [_c('router-view')], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };