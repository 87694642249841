import store from '@/state/store.js';
export default {
  name: 'user-segments',
  data() {
    return {
      total: ''
    };
  },
  computed: {
    goldUsersCount() {
      return store.getters['admin/users/goldUsersTotal'];
    },
    silverUsersCount() {
      return store.getters['admin/users/silverUsersTotal'];
    },
    bronzeUsersCount() {
      return store.getters['admin/users/bronzeUsersTotal'];
    }
  }
};